<template>
  <v-card>
    <v-card-title class="popup-title">공지사항 등록</v-card-title>
    <v-card-text class="mt-4">
      <div class="a_wrap mt-4">
        <table
          width="100%" cellspacing="0" cellpadding="4px" class="a_box"
        >
          <colgroup>
            <col width="10%">
            <col width="70%">
            <col width="10%">
            <col width="10%">
          </colgroup>
          <tbody>
          <tr>
            <td class="td_gy">제목</td>
            <td class="td_dark_gray02 text-left">
              <input v-model="editItem.subject" type="text"  class="blue_input text-left" style="width: 100%;">
            </td>
            <td class="td_gy">공지여부</td>
            <td class="td_dark_gray02">
              <v-checkbox
                v-model="editItem.noticeYn"
                true-value="Y"
                false-value="N"
                hide-details
                dense
                class="pa-0 ma-0"
              />
            </td>
          </tr>
          <tr>
            <td class="td_gy">내용</td>
            <td colspan="3" class="td_dark_gray02" style="text-align: left; padding: 6px">
              <ckeditor
                v-model="editItem.content"
                :editor="editorOptions.editor"
                :config="editorOptions.editorConfig"

              ></ckeditor>
            </td>
          </tr>
          <tr>
            <td class="td_gy">첨부파일</td>
            <td colspan="3" class="td_dark_gray02 text-left pt-4 pb-2">
              <v-file-input
                v-model="files"
                dense
                multiple
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                :label="fileHint"
                :show-size="1000"
                :truncate-length="40"
                :error="fileError"
                :error-messages="fileError ? fileErrorMessage : null"
                :disabled="!canAddFile"
                @change="onChangeFiles"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    label
                    close
                    color="primary"
                    @click:close="removeFile(index)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <div
                v-for="(file, index) in editItem.files"
                :key="index"
              >
                <v-chip
                  label
                  outlined
                  close
                  class="ma-1"
                  @click="downloadFile(file)"
                  @click:close="deleteFile(index, file)"
                >
                  {{ file.convFileName }}
                </v-chip>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="butt">
          <button id="confirm05" type="button" class="pop-btn" @click="save">등록</button>
          <button id="close05" type="button" class="pop-btn" @click="close">취소</button>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
import AutoFormat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'

export default {
  name: 'NoticeWriteDialog',
  props: {
    item: Object
  },
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      editorOptions: {
        editor: ClassicEditor,
        editorConfig: {
          plugins: [
            Essentials,
            UploadAdapter,
            AutoFormat,
            Bold,
            Italic,
            BlockQuote,
            CKFinder,
            Heading,
            Image,
            ImageCaption,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            Indent,
            Link,
            List,
            Paragraph,
            Table,
            TableToolbar,
            TableProperties,
            TableCellProperties,
            TextTransformation,
            Underline,
            SourceEditing,
            FontSize,
            FontColor,
            Alignment,
          ],
          toolbar: {
            shouldNotGroupWhenFull: true,
            items: [
              'heading',
              '|',
              'bold', 'italic', 'underline', 'fontSize', 'fontColor',
              '|',
              'link', 'bulletedList', 'numberedList',
              '|',
              'alignment:left', 'alignment:right', 'alignment:center',
              '|',
              'indent', 'outdent',
              '|',
              'imageUpload', 'blockQuote', 'insertTable',
              '|',
              'undo', 'redo'
            ]
          },
          alignment: {
            options: ['left', 'center', 'right']
          },
          image: {
            toolbar: [
              'imageStyle:block',
              'imageStyle:alignLeft',
              'imageStyle:side',
              '|',
              'imageTextAlternative'
            ]
          },
          table: {
            contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells',
              'tableProperties',
              'tableCellProperties'
            ]
          },
          ckfinder: {
            // Upload the images to the server using the CKFinder QuickUpload command.
            uploadUrl: './api/notice/file/upload'
          }
        }
      },
      editItem: {},
      files: [],
      fileError: false,
      fileErrorMessage: '등록가능한 파일수를 초과하였습니다.',
    }
  },
  computed: {
    fileHint() {
      const fileCount = (this.files || []).length + (this.editItem.files || []).length
      return `첨부 가능 파일 수: ${fileCount} / 5`
    },
    canAddFile() {
      const fileCount = (this.editItem.files || []).length
      return 5 > fileCount
    }
  },
  mounted() {
    if (this.item.seq) {
      this.$http.get(`/api/notice/${this.item.seq}`)
        .then(({ data }) => {
          this.editItem = data
        })
    }
  },
  methods: {
    save() {
      let url = '/api/notice/create'
      if (this.editItem.seq) {
        url = '/api/notice/update'
      }

      let formData = new FormData();
      for (let file of this.files) {
        formData.append("files", file)
      }

      this.editItem.attachYn = (this.files.length > 0 || this.editItem.files.length > 0) ? 'Y' : 'N'

      formData.append("param", new Blob([ JSON.stringify(this.editItem) ], {type : "application/json"}))

      this.$http.post(url, formData)
        .then(({ data }) => {
          if (data === 'success') {
            alert('저장되었습니다.')
            this._close(data)
          }
        })
    },
    close() {
      this._close()
    },
    _close(result) {
      this.$emit('close', result)
    },
    removeFile(index) {
      this.files.splice(index, 1)
    },
    deleteFile(index, file) {
      if (confirm(`[${file.fileName}] 파일을 삭제하시겠습니까?`)) {
        this.$http.post(`/api/notice/delete/file/${file.seq}`)
          .then(() => {
            this.editItem.files.splice(index, 1)
          })
      }
    },
    onChangeFiles() {
      const fileCount = (this.files.length || []) + (this.editItem.files || []).length
      if (fileCount > 5) {
        this.fileError = true
        this.files = []
      } else {
        this.fileError = false
      }
    },
    getFileSize(data) {
      let size = "";
      if (data < 1024) size = data + "B";
      else if (data < 1024 * 1024) size = parseInt (data / 1024) + "KB";
      else if (data < 1024 * 1024 * 1024) size = parseInt (data / (1024 * 1024)) + "M";
      else size = parseInt (data / (1024 * 1024 * 1024)) + "G";

      return size;
    },
    downloadFile(fileItem) {
      this.$http.post(`/api/notice/file/download/${fileItem.seq}`, {},{
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileItem.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloadDialog = false
      }).catch(() => {
        alert("파일 다운로드 실패")
        this.downloadDialog = false
      })
    },
  }
}
</script>

<style>
.ck-editor p {
  text-align: left;
}
.ck-editor__editable {
  min-height: 450px;
}
</style>
