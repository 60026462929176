<template>
  <v-container fluid>
    <p class="text-h5 text-left">공지사항</p>
    <div class="autho">
      <select v-model="param.searchType" class="yon" style="background-color: white; -moz-appearance: caret; -webkit-appearance: caret">
        <option :value="null">전체</option>
        <option value="SUBJECT">제목</option>
        <option value="CONTENT">내용</option>
      </select>
      <div class="ml-2 shbtn01">
        <input v-model="param.keyword" type="text" class="code" @keydown.enter="search">
        <a @click="search"></a>
      </div>
      <v-btn
        v-if="isAdmin"
        small
        color="primary"
        elevation="0"
        class="ml-4"
        @click="writeDialog = true"
      >
        <v-icon small>mdi-plus</v-icon> 등록
      </v-btn>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="7%">
        <col width="53%">
        <col width="7%">
        <col width="8%">
        <col width="10%">
        <col width="8%">
        <col v-if="isAdmin" width="5%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue">제목</th>
        <th class="td_dark_blue">첨부파일</th>
        <th class="td_dark_blue">작성자</th>
        <th class="td_dark_blue">등록일</th>
        <th class="td_dark_blue">조회수</th>
        <th v-if="isAdmin" class="td_dark_blue">

        </th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th :colspan="isAdmin ? 7 : 6" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index">
          <td class="td_blue cen bo" @click="openDialog(item)">
            <v-chip
              v-if="item.noticeYn === 'Y'"
              small
              label
              outlined
              color="error"
            >공지</v-chip>
            <span v-else>{{ item.seq }}</span>
          </td>
          <td class="td_blue bo text-left px-2" @click="openDialog(item)">{{ item.subject }}</td>
          <td class="td_blue cen bo" @click="openDialog(item)">
            <v-icon v-if="item.attachYn === 'Y'">mdi-paperclip</v-icon>
          </td>
          <td class="td_blue cen bo" @click="openDialog(item)">{{ item.writerName }}</td>
          <td class="td_blue cen bo" @click="openDialog(item)">{{ item.createdDate }}</td>
          <td class="td_blue cen bo" @click="openDialog(item)">{{ item.views }}</td>
          <td v-if="isAdmin" class="td_blue cen bo">
            <v-btn
              small
              color="primary"
              @click="openWriteDialog(item)"
            >
              수정
            </v-btn>
          </td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" :colspan="isAdmin ? 7 : 6">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>
    <tech-pagination :page.sync="pageable.page" :page-size="10" :total-elements="pageable.totalElements"/>

    <v-dialog v-model="writeDialog" max-width="1003">
      <notice-write-dialog v-if="writeDialog" :item="selectedItem" @close="closeDialog"/>
    </v-dialog>

    <v-dialog v-model="viewDialog" max-width="1003">
      <notice-view-dialog v-if="viewDialog" :item="selectedItem" @close="closeDialog"/>
    </v-dialog>
  </v-container>
</template>

<script>
import TechPagination from '@/components/Pagination'
import NoticeWriteDialog from "@/views/notice/dialog/NoticeWriteDialog.vue";
import NoticeViewDialog from "@/views/notice/dialog/NoticeViewDialog.vue";
import {mapGetters} from "vuex";
export default {
  name: 'NoticeList',
  components: {NoticeViewDialog, NoticeWriteDialog, TechPagination},
  data() {
    return {
      loading: false,
      writeDialog: false,
      viewDialog: false,
      param: {
        start: 0,
        end: 10,
        searchType: null,
        keyword: null
      },
      pageable: {
        page: 1,
        totalElements: 0
      },
      items: [],
      selectedItem: {}
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isManager: 'isManager',
      isTeamLeader: 'isTeamLeader'
    })
  },
  watch: {
    'pageable.page' () {
      this.searchList()
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      if (this.pageable.page === 1) {
        this.searchList()
      } else {
        this.pageable.page = 1
      }
    },
    searchList() {
      this.loading = true

      this.param.start = (this.pageable.page - 1) * 10
      this.param.end = this.pageable.page * 10

      this.$http.get('/api/notice', {params: this.param})
      .then(({ data }) => {
        this.selectedIndex = -1
        this.selectedItem = {}
        this.items = data
        if (data.length > 0) {
          this.pageable.totalElements = data[0].totalElements
        } else {
          this.pageable.totalElements = 0
        }
        this.loading = false
      })
    },
    openDialog(item) {
      this.selectedItem = item
      this.viewDialog = true
    },
    openWriteDialog(item) {
      this.selectedItem = item
      this.writeDialog = true
    },
    closeDialog(result) {
      this.writeDialog = false
      this.viewDialog = false
      this.selectedItem = {}
      if (result === 'success') {
        this.search()
      }
    },
    downloadFile(fileItem) {
      let url = `/api/sys/company/files/download/${fileItem.fileId}`

      if (!fileItem.fileId) {
        url = `/api/file/download/temp/${fileItem.tempKey}`
      }
      this.$http.post(url, {},{
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileItem.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloadDialog = false
      }).catch(() => {
        alert("파일 다운로드 실패")
        this.downloadDialog = false
      })
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
